a:active, a:link, a:visited, a:focus {
  color: var(--dark);
}

button.btn, a.btn {
  border: none;
  padding: 1em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

button.btn svg {
  margin-right: 10px;
}

button.btn.full-width, a.btn.full-width {
  width: 100%;
}

button.btn.btn-primary, a.btn.btn-primary {
  background-color: var(--primary-color);
  color: var(--dark);
  fill: var(--dark);
  border-radius: 10px;
}

button.btn.btn-danger, a.btn.btn-danger {
  background-color: var(--btn-cancel);
  color: var(--light);
  fill: var(--light);
}

button.btn.btn-primary-outline, a.btn.btn-primary-outline {
  background-color: var(--light);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  fill: var(--primary-color);
}

button.btn.btn-dark-outline, a.btn.btn-dark-outline {
  background-color: var(--light);
  border: 1px solid var(--primary-dark);
  color: var(--primary-dark);
  fill: var(--primary-dark);
}

button.btn.btn-dark, a.btn.btn-dark {
  background-color: var(--primary-dark);
  color: var(--light);
  fill: var(--light);
}

button.btn.btn-link, a.btn.btn-link {
  background-color: transparent;
  font-weight: 600;
}

button.btn.btn-link-primary, a.btn.btn-link-primary {
  background-color: transparent;
  font-weight: 600;
  color: var(--primary-color);
  fill: var(--primary-color);
}

button.btn.btn-link-dark, a.btn.btn-link-dark {
  background-color: transparent;
  font-weight: 600;
  color: var(--primary-dark);
  fill: var(--primary-dark);
}

ul, ol {
  margin-top: 0;
  margin-bottom: 24px;
  padding-left: 24px
}

ul {
  list-style: disc
}

ol {
  list-style: decimal
}

li>ul, li>ol {
  margin-bottom: 0
}

h3, .h3, blockquote {
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0px
}

hr {
  border: 0;
  display: block;
  height: 1px;
  background: var(--primary-dark);
  margin-top: 24px;
  margin-bottom: 24px
}

h4, h5, h6, .h4, .h5, .h6 {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.1px
}

select {
  padding: 1em;
  background-color: var(--light);
}

select:focus-visible, select {
  outline: none;
  border: none;
}

.text-sm {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.1px
}

.text-xs {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px
}

.list-reset {
  list-style: none;
  padding: 0
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important
}

.screen-reader-text:focus {
  border-radius: 2px;
  box-shadow: 0 0 2px 2px var(--dark);
  clip: auto !important;
  display: block;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: var(--primary-color);
  color: var(--primary-ligth) !important;
  border: none;
  height: auto;
  left: 8px;
  padding: 16px 32px;
  top: 8px;
  width: auto;
  z-index: 100000
}

.shimmer {
  background-image: linear-gradient(
    90deg,
    #EEE 0%, #EEE 40%,
    #DDD 50%, #DDD 55%,
    #EEE 65%, #EEE 100%
  );
  background-size: 400%;
  padding: 0.2rem;
  border-radius: 5px;
  height: 30px;
  width: 100px;
  animation: shimmer 1500ms infinite;
}

@keyframes shimmer {
  from { background-position: 100% 100%; }
  to { background-position: 0 0; }
  
}

.cta {
  text-align: center
}

.cta .section-title {
  margin-bottom: 40px
}


.section-inner {
  position: relative;
  padding-top: 48px;
  padding-bottom: 48px
}

.text-left {
  text-align: left
}

.p-bar {
  padding-top: 55px;
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.mt-0 {
  margin-top: 0
}

.mb-0 {
  margin-bottom: 0
}

.mb-8 {
  margin-bottom: 8px
}

.mt-8 {
  margin-top: 8px;
}

.mt-24 {
  margin-top: 24px;
}

.ml-24 {
  margin-left: 24px;
}

.pt-24 {
  padding-top: 24px
}

.pb-24 {
  padding-bottom: 24px
}

.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-color: var(--primary-light)!important;
}

.mdc-tab--active .mdc-tab__text-label {
  color: var(--primary-dark)!important;
}

.mdc-tab__ripple::before, .mdc-tab__ripple::after {
  background-color: var(--primary-light)!important;
}

.btn-container {
  display: grid;
  gap: 1rem;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 15rem), 1fr));
}

@media (min-width: 641px) {
  .cta {
    text-align: left
  }

  .cta .section-inner {
    padding: 64px 32px
  }

  .cta .section-title {
    margin-bottom: 0;
    padding-right: 24px
  }

  .section-inner {
    padding-top: 88px;
    padding-bottom: 88px
  }
}

.storePage_sticky {
  margin: 1em 5px;
  position: sticky;
  top: 70px;
  background-color: var(--light);
  scroll-snap-type: y mandatory;
  display: flex;
  gap: 5px;
}

.footer_divider {
  margin: 30px 0;
}
