.header_container {
  position: fixed;
  height: 70px;
  z-index: 10;
  background-color: var(--light);
}

.image-header {
  width: 100%;
  height: 50px;
}

.image-header img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}