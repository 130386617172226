.product-container {
  padding: 1em;
}

.product__image {
  margin: 20px auto;
  position: relative;
}

.imageProduct_container {
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 var(--shadow);
  box-sizing: border-box;
}

.imageProduct_container > .imageProduct_container-item {
  min-width: 100%;
  scroll-snap-align: start;
}

.imageProduct_container-item {
  width: 100%;
  max-width: 100%;
  height: 25em;
  max-height: 25em;
  overflow: hidden;
}

.imageProduct_container-item img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.product__image-overlay {
  position: absolute;
  bottom: -22px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 1em;
  width: 70%;
  color: var(--primary-dark);
  background: var(--glass-color);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 20px );
  -webkit-backdrop-filter: blur( 20px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.product__image-overlay-content h2,
.product__image-overlay-content h3 {
  margin: 0;
}

.product__image-overlay-content h2 {
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product__description {
  margin-top: 40px;
}

.product__description-description {
  box-shadow: 0 4px 5px 1px var(--shadow);
  background-color: var(--glass-color);
  border-radius: 3px;
  padding: 2em;
  margin-top: 10px;
  overflow: hidden;
}

.product__description-description pre {
  white-space: pre-wrap;
}

.imageProduct_container::-webkit-scrollbar {
  height: 5px;
  border-radius: 50px;
}

.imageProduct_container::-webkit-scrollbar-track {
  background-color: transparent;

}

.imageProduct_container::-webkit-scrollbar-thumb {
  background-color: var(--primary-dark);
  border-radius: 50px;
}

@media only screen and (min-width: 1024px) {
  .imageProduct_container {
    max-width: 80%;
  }

  .product__description-description {
    padding: 20px 0 20px 40px;
  }
}