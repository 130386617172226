.hero-inner {
  padding: 0 2em;
}

.hero-copy {
  position: relative;
  z-index: 1
}

.hero-cta {
  margin-bottom: 40px;
  display: grid;
  gap: 1rem;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 15rem), 1fr));
}

.hero-figure {
  position: relative
}

.hero-figure svg {
  width: 100%;
  height: auto
}

.hero-figure::before, .hero-figure::after {
  content: '';
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100%
}

.hero-figure::before {
  top: -57.8%;
  left: -1.3%;
  width: 152.84%;
  height: 178.78%;
  fill: red;
}

.hero-figure::after {
  top: -35.6%;
  left: 99.6%;
  width: 57.2%;
  height: 87.88%;
  background-image: url("../../res/images/hero-top-illustration.svg")
}

.hero-figure-box {
  position: absolute;
  top: 0;
  will-change: transform
}

.hero-figure-box-01, .hero-figure-box-02, .hero-figure-box-03, .hero-figure-box-04, .hero-figure-box-08 {
  overflow: hidden;
}

.hero-figure-box-01::before, .hero-figure-box-02::before, .hero-figure-box-03::before, .hero-figure-box-04::before, .hero-figure-box-08::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%
}

.hero-figure-box-01 {
  left: 103.2%;
  top: 41.9%;
  width: 28.03%;
  height: 37.37%;
  background: linear-gradient(to left top, var(--light), var(--primary-color));
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.hero-figure-box-01::before {
  background: linear-gradient(to left, var(--primary-dark) 0%, var(--dark) 60%);
  -webkit-transform: rotateZ(45deg) scale(1.5);
  transform: rotateZ(45deg) scale(1.5)
}

.hero-figure-box-02 {
  left: 61.3%;
  top: 64.1%;
  width: 37.87%;
  height: 50.50%;
  background: var(--primary-light);
  -webkit-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.hero-figure-box-02::before {
  -webkit-transform: rotateZ(-45deg) scale(1.5);
  transform: rotateZ(-45deg) scale(1.5)
}

.hero-figure-box-03 {
  left: 87.7%;
  top: -56.8%;
  width: 56.81%;
  height: 75.75%;
  background: linear-gradient(to left top, var(--primary-light), var(--dark));
}

.hero-figure-box-03::before {
  background: linear-gradient(to left, var(--dark) 0%, var(--primary-dark) 60%);
  -webkit-transform: rotateZ(45deg) scale(1.5);
  transform: rotateZ(45deg) scale(1.5)
}

.hero-figure-box-04 {
  left: 54.9%;
  top: -8%;
  width: 45.45%;
  height: 60.60%;
  background: linear-gradient(to left top, var(--primary-color), var(--dark));
  -webkit-transform: rotateZ(-135deg);
  transform: rotateZ(-135deg);
  border-radius: 10px;
}

.hero-figure-box-04::before {
  background: linear-gradient(to top, var(--gray) 0%, var(--dark) 60%);
  -webkit-transform: rotateZ(-45deg) scale(1.5);
  transform: rotateZ(-45deg) scale(1.5)
}

.hero-figure-box-05, .hero-figure-box-06, .hero-figure-box-07 {
  box-shadow: -20px 32px 64px var(--shadow);
  border-radius: 10px;
}

.hero-figure-box-05 {
  left: 17.4%;
  top: 13.3%;
  width: 64%;
  height: 73.7%;
  background-color: var(--primary-light);
  -webkit-transform: perspective(500px) rotateY(-15deg) rotateX(8deg) rotateZ(-1deg);
  transform: perspective(500px) rotateY(-15deg) rotateX(8deg) rotateZ(-1deg)
}

.hero-figure-box-06 {
  background-color: var(--primary-color);
  left: 65.5%;
  top: 6.3%;
  width: 30.3%;
  height: 40.4%;
  -webkit-transform: rotateZ(20deg);
  transform: rotateZ(20deg)
}

.hero-figure-box-07 {
  left: 1.9%;
  top: 42.4%;
  width: 12.12%;
  height: 16.16%;
  -webkit-transform: rotateZ(20deg);
  transform: rotateZ(20deg)
}

.hero-figure-box-07 {
  background-color: var(--dark);
}

.hero-figure-box-08 {
  left: 27.1%;
  top: 81.6%;
  width: 19.51%;
  height: 26.01%;
  background: var(--primary-dark);
  -webkit-transform: rotateZ(-22deg);
  transform: rotateZ(-22deg);
  border-radius: 10px;
}

.hero-figure-box-08::before {
  -webkit-transform: rotateZ(45deg) scale(1.5);
  transform: rotateZ(45deg) scale(1.5)
}

.hero-image-container {
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.hero-image-container img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

@media (min-width: 641px) {
  .hero-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5em;
  }

  .hero-copy {
    padding-right: 64px;
    min-width: 552px;
    width: 552px
  }

  .hero-cta {
    margin: 0
  }

  .hero-cta .btn {
    min-width: 170px
  }

  .hero-cta .btn:first-child {
    margin-right: 16px
  }

  .hero-figure svg {
    width: auto
  }
}

@media (max-width: 640px) {
  .hero-cta {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto
  }

  .hero-cta .btn {
    display: flex
  }

  .hero-cta .btn+.btn {
    margin-top: 16px
  }

  .hero-figure::after, .hero-figure-box-03, .hero-figure-box-04 {
    display: none
  }
}