.site-footer {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px
}

.site-footer a:hover, .site-footer a:active {
  text-decoration: underline
}

.site-footer-inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 48px;
}

.site-footer-inner  ul {
  margin: 0;
}

.footer-social-links, .footer-copyright {
  flex: none;
  width: 100%;
  display: inline-flex;
  justify-content: center
}

.footer-social-links {
  margin-bottom: 24px
}

ul.footer-social-links li {
  align-items: center;
}

@media (min-width: 641px) {
  .site-footer-inner {
    justify-content: space-between;
    padding-top: 55px;
  }

  .footer-social-links, .footer-copyright {
    flex: 50%
  }

  .footer-copyright {
    justify-content: flex-start
  }
}