.store-page {
  display: flex;
  flex-direction: column;
  padding: 90px 1em 1rem;
  overflow: auto;
}

.store-page__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1em;
  margin-bottom: 20px;
  font-size: small;
}

.store-page__header div {
  height: 200px;
  width: 200px;
  border-radius: 500px;
  overflow: hidden;
  box-shadow: 0 5px 10px 0 var(--shadow);
}

.store-page__header img{
  height: 200px;
  width: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}
