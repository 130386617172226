.promo-image-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.promo-image-container img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
}

.btn-promo {
  position: absolute;
  width: 80%;
  margin: auto;
  height: 60px;
  top: 85%;
  left: 10%;
  color: black!important;
  font-size: large;
  font-weight: bolder;
}