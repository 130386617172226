
.has-bottom-divider {
  position: relative
}

.has-bottom-divider::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: var(--dark);
}

.hero {
  text-align: center;
  padding-top: 48px;
  padding-bottom: 88px
}

.features-container {
  padding: 0 1em;
}

.features-wrap {
  display: grid;
  gap: 4rem;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 15rem), 1fr));
  margin: 2rem 0;
  width: 80%;
  margin: auto;
}

.features-wrap:first-of-type {
  margin-top: -16px
}

.features-wrap:last-of-type {
  margin-bottom: -16px
}

.pricing-header {
  margin-bottom: 48px
}

.body-wrap {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: auto;
}

.boxed-container {
  max-width: 1440px;
  margin: 0 auto;
  box-shadow: 0 24px 48px rgba(21, 24, 29, 0.24);
  mix-blend-mode: multiply;
  mix-blend-mode: normal
}

main {
  flex: 1 0 auto
}

.footer-social-links li {
  display: inline-flex
}

.footer-social-links li+li {
  margin-left: 16px
}

.footer-social-links li a {
  padding: 8px
}

.footer-links li+li {
  margin-left: 24px
}


@media (min-width: 641px) {
  .hero {
    text-align: left;
    padding-top: 64px;
    padding-bottom: 88px
  }

  .pricing-header {
    margin-bottom: 52px
  }

  .pricing .section-paragraph {
    padding-left: 90px;
    padding-right: 90px
  }

  .features-wrap:first-of-type {
    margin-top: -24px
  }

  .features-wrap:last-of-type {
    margin-bottom: -24px
  }

  .site-footer {
    margin-top: 20px
  }

  .footer-links, .footer-social-links {
    justify-content: center;
  }

  .footer-links {
    order: 1;
    margin-bottom: 0
  }
}