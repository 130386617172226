
.containerMain__error {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.containerMain__error.fullScreen {
  height: 100vh;
}

.containerMain__error--information {
  margin: auto;
  text-align: center;
  width: 95%;
  font-size: 14px;
  z-index: 1;
  color: var(--gray);
}

.containerMain__error--information img {
  width: 200px;
  height: 150px;
  padding-bottom: 2em;
  filter: grayscale(100%) opacity(0.3)
}

.containerMain__error--information p {
  font-size: large;
  font-weight: 600;
}

.containerMain__error--btn-next {
  position: fixed;
  bottom: 0;
}
