.container-banner {
  display: grid;
  gap: 4rem;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 15rem), 1fr));
  margin: 2rem 0;
  width: 80%;
  margin: auto;
}

.cta .section-inner {
  padding: 48px 16px;
}

.section-title {
  color: var(--light);
}

.cta-inner {
  position: relative;
  background: var(--primary-dark);
  overflow: hidden;
  border-radius: 20px;
}

.cta-inner::before {
  content: '';
  position: absolute;
  right: 98px;
  top: -117px;
  width: 160px;
  height: 187px;
}

.cta-inner>* {
  position: relative
}

@media (min-width: 641px) {
  .cta-inner {
    display: flex;
    align-items: center;
    justify-content: space-between
  }
  .cta .section-inner {
    padding: 3em;
  }
}
