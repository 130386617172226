.container__load {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.container__load.fullScreen {
  height: 100vh;
}

.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 10px solid var(--dark);
  animation: spinner-bulqg1 1.5s infinite linear alternate,
       spinner-oaa3wk 3s infinite linear;
}

@keyframes spinner-bulqg1 {
  0% { clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%); }

  12.5% { clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%); }

  25% { clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%); }

  50% { clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%); }

  62.5% { clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%); }

  75% { clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%); }

  100% { clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%); }
}

@keyframes spinner-oaa3wk {
  0% { transform: scaleY(1) rotate(0deg); }

  49.99% { transform: scaleY(1) rotate(135deg); }

  50% { transform: scaleY(-1) rotate(0deg); }

  100% { transform: scaleY(-1) rotate(-135deg); }
}